<template>
  <div class="payment-auth-list">
    <div v-if="paymentAuthList.length">
      <v-list>
        <strong v-if="additionalInfoPayment === 'WALLET_PAYPAL'">
          {{ $t("paymentTypeList.yourWalletPaypal") }}
        </strong>
        <strong v-else> {{ $t("paymentTypeList.yourCard") }} </strong>
        <v-list-item-group
          v-model="selectedRow"
          mandatory
          @change="selectPaymentAuth"
        >
          <v-list-item
            v-for="(paymentAuth, index1) in paymentAuthList"
            :key="paymentAuth.paymenthAuthId"
          >
            <v-list-item-avatar rounded="0" class="payment-radio">
              <v-icon v-if="selectedRow === index1">$radioOn</v-icon>
              <v-icon v-else>$radioOff</v-icon>
            </v-list-item-avatar>
            <v-list-item-avatar rounded="0" class="card-img">
              <img
                v-if="additionalInfoPayment === 'WALLET_PAYPAL'"
                src="/credit-card/paypal-transparent-64px.png"
                alt="Logo PayPal"
              />
              <img
                v-else
                :src="getCreditCardImage(paymentAuth.creditCard)"
                alt="Immagine carta di credito"
              />
            </v-list-item-avatar>
            <v-list-item-content
              v-if="additionalInfoPayment === 'WALLET_PAYPAL'"
            >
              {{ paymentAuth.creditCard }}
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-list-item-title>
                {{ paymentAuth.creditCard | ccard }}
              </v-list-item-title>

              <v-list-item-subtitle v-if="paymentAuth.expireDate | ccexp">
                Scadenza: {{ paymentAuth.expireDate | ccexp }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                v-if="
                  showRemoveCard && additionalInfoPayment === 'WALLET_PAYPAL'
                "
                small
                icon
                @click.prevent.stop="
                  removePaymentAuth(paymentAuth.userPaymentAuthId)
                "
                class="remove-card"
                aria-label="Rimuovi questo wallet"
                title="Rimuovi questo wallet"
              >
                <v-icon>$delete</v-icon>
              </v-btn>
              <v-btn
                v-else-if="showRemoveCard"
                small
                icon
                @click.prevent.stop="
                  removePaymentAuth(paymentAuth.userPaymentAuthId)
                "
                class="remove-card"
                aria-label="Rimuovi questa carta"
                title="Rimuovi questa carta"
              >
                <v-icon>$delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
        <strong v-if="$te(`paymentTypeList.moreOptions`)">{{
          $t("paymentTypeList.moreOptions")
        }}</strong>
      </v-list>

      <!-- <div class="new-card d-flex align-center mt-2 mb-4">
        <v-btn
          href="#"
          color="primary"
          @click="addNewCard()"
          small
          v-if="paymentAuthList.length > 0 && paymentAuthList.length < 4"
        >
          {{ $t("paymentTypeList.addNewCard") }}
        </v-btn>
      </div> -->
    </div>
    <div v-if="showAddNewCardWarning">
      <v-alert type="warning" dense outlined>
        <span
          v-if="additionalInfoPayment === 'WALLET_PAYPAL'"
          v-html="$t('paymentTypeList.addNewWalletPaypalWarning')"
        />
        <span v-else v-html="$t('paymentTypeList.addNewCardWarning')" />
      </v-alert>
    </div>
  </div>
</template>
<script>
import PaymentTypeService from "~/service/paymentTypeService";
import creditCard from "~/mixins/creditCart";
export default {
  name: "PaymentAuthList",
  props: {
    paymentTypeId: { type: Number, required: true },
    showAddNewCardWarning: { type: Boolean, required: true },
    showRemoveCard: { type: Boolean, required: false, default: true },
    filterByUserPaymentAuthId: { type: Number, required: false },
    additionalInfoPayment: { type: String, required: false, default: "" }
  },
  mixins: [creditCard],
  data() {
    return {
      paymentAuthList: [],
      selectedPaymentAuth: null,
      selectedRow: null
    };
  },
  methods: {
    addNewCard() {
      this.$emit("addNewCard");
    },
    async loadAvailablePaymentAuth() {
      //call to api
      this.paymentAuthList = await PaymentTypeService.getPaymentsAuth(
        this.paymentTypeId
      );

      this.$emit("paymentAuthList", this.paymentAuthList);

      var tmp = [];
      if (this.filterByUserPaymentAuthId) {
        for (let i = 0; i < this.paymentAuthList.length; i++) {
          if (
            this.filterByUserPaymentAuthId ==
            this.paymentAuthList[i].userPaymentAuthId
          ) {
            tmp[0] = this.paymentAuthList[i];
            break;
          }
        }
        this.paymentAuthList = tmp;
      }

      if (this.paymentAuthList.length > 0) {
        for (let i = 0; i < this.paymentAuthList.length; i++) {
          if (this.paymentAuthList[i].defaultAuth === true) {
            this.selectedRow = i;
            this.selectPaymentAuth();
            break;
          }
        }
      }
    },

    selectPaymentAuth() {
      if (this.selectedRow >= 0) {
        let paymentAuth = this.paymentAuthList[this.selectedRow];
        this.$emit("selectPaymentAuth", paymentAuth.userPaymentAuthId);
      }
      // else {
      //   this.$emit("selectPaymentAuth", null);
      // }
    },
    async removePaymentAuth(paymentAuthId) {
      await PaymentTypeService.deletePaymentAuth(paymentAuthId);
      this.$emit("selectPaymentAuth", null);
      this.loadAvailablePaymentAuth();
    }
  },
  mounted() {
    this.loadAvailablePaymentAuth();
  }
};
</script>
<style lang="scss" scoped>
.payment-radio {
  img {
    width: 20px !important;
  }
}
.card-img {
  margin-right: 16px;
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .payment-radio {
    display: none;
  }
}
</style>
