<template>
  <div class="prebilled-amount">
    <v-tooltip
      top
      v-if="
        $te(
          'prebilled.' + mode + '.tooltipBaseOnPaymentTypeId.' + paymentTypeId
        )
      "
      content-class="prebilled-amount-tooltip"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-alert dense outlined type="info" v-bind="attrs" v-on="on">
          <div>
            {{ $t("prebilled." + mode + ".text") }}
            <strong>
              {{ $n(prebilledAmount, "currency") }}
            </strong>
          </div>
        </v-alert>
      </template>

      <span
        v-html="
          $t(
            'prebilled.' + mode + '.tooltipBaseOnPaymentTypeId.' + paymentTypeId
          )
        "
      />
    </v-tooltip>
    <v-alert v-else dense outlined type="info">
      <div>
        {{ $t("prebilled." + mode + ".text") }}
        <strong>
          {{ $n(prebilledAmount, "currency") }}
        </strong>
      </div>
    </v-alert>
  </div>
</template>
<script>
import CartService from "~/service/cartService";

export default {
  name: "PrebilledAmount",
  props: {
    mode: { type: String, required: true },
    paymentTypeId: {
      type: Number,
      required: false
    },
    orderId: { type: Number, required: false },
    paymentDueDateIds: { type: Array, required: false }
  },
  data: function() {
    return {
      prebilledAmount: null
      // on: null,
      // attrs: null
    };
  },
  methods: {
    async getPrebilledAmount() {
      let vm = this;
      if (vm.mode == "cart") {
        this.prebilledAmount = await CartService.getPreBilledAmount(
          vm.paymentTypeId
        );
      } else if (vm.mode == "order") {
        vm.prebilledAmount = await CartService.getPreBilledAmount(
          vm.paymentTypeId,
          vm.orderId
        );
      } else if (vm.mode == "duedate") {
        //TODO implement prebilled duedate
      } else if (vm.mode == "addAuth") {
        // anche server side al momento è implementato 1,01€ fisso
        vm.prebilledAmount = 1.01;
        if (
          global.config.addAuthPaymentTypePrebilledAmount &&
          global.config.addAuthPaymentTypePrebilledAmount[vm.paymentTypeId] !=
            undefined
        ) {
          // check if prebilled is mapped in config.js because if a problem to get it server
          vm.prebilledAmount =
            global.config.addAuthPaymentTypePrebilledAmount[vm.paymentTypeId];
        }
      }
    }
  },
  created() {
    this.getPrebilledAmount();
  }
};
</script>
