import axios from "~/plugins/axios";
import CartService from "~/service/cartService";

export default {
  list() {
    return axios.get("/ebsn/api/meal-ticket-type/list").then(data => {
      return data.data.data.mealTicketTypes;
    });
  },
  select(paymentTypeId, select) {
    return axios
      .post("/ebsn/api/meal-ticket-type/select", null, {
        params: { payment_type_id: paymentTypeId, select: select }
      })
      .then(data => {
        CartService.getCart(true);
        return data.data.data.mealTicketTypes;
      });
  },
  logout(paymentTypeId) {
    return axios
      .post("/ebsn/api/meal-ticket-type/logout", {
        params: { payment_type_id: paymentTypeId }
      })
      .then(data => {
        return data.data.data.mealTicketTypes;
      });
  },
  getEdenred(paymentTypeId) {
    return axios
      .get("/ebsn/api/meal-ticket/edenred/detail", {
        params: { payment_type_id: paymentTypeId }
      })
      .then(data => {
        return data.data.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  updateEdenred(paymentTypeId, data) {
    //sull'url il parametro, nel body il json
    return axios
      .post("/ebsn/api/meal-ticket-type/update-data", data, {
        params: { payment_type_id: paymentTypeId }
      })
      .then(data => {
        return data.data.data;
      });
  },
  validateToken(token) {
    return axios
      .get("/ebsn/api/meal-ticket/edenred/mobile-callback", {
        params: { code: token }
      })
      .then(data => {
        return data;
      });
  },
  validateTokenTC(token) {
    return axios
      .get("/ebsn/api/meal-ticket/edenred/mobile-callback-tc", {
        params: { code: token }
      })
      .then(data => {
        return data;
      });
  },
  validateLogoutToken() {
    return axios
      .get("/ebsn/api/meal-ticket/edenred/mobile-logout")
      .then(data => {
        return data;
      });
  },
  validateLogoutTokenTC() {
    return axios
      .get("/ebsn/api/meal-ticket/edenred/mobile-logout-tc")
      .then(data => {
        return data;
      });
  },
  getPellegrini(paymentTypeId) {
    return axios
      .get("/ebsn/api/meal-ticket/pellegrini/detail", {
        params: { payment_type_id: paymentTypeId }
      })
      .then(data => {
        return data.data.data;
      });
  },
  loginPellegrini(user, pwd) {
    return axios
      .get("/ebsn/api/meal-ticket/pellegrini/login", {
        params: { user: user, pwd: pwd }
      })
      .then(data => {
        return data.data;
      });
  },
  logoutPellegrini() {
    return axios.get("/ebsn/api/meal-ticket/pellegrini/logout").then(data => {
      return data.data.data;
    });
  },
  updatePellegrini(paymentTypeId, data) {
    return this.updateEdenred(paymentTypeId, data);
  }
};
