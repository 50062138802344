export default {
  methods: {
    getCreditCardImage(number) {
      if (number) {
        var re = {
          electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+(\**)\d+$/,
          maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+(\**)\d+$/,
          dankort: /^(5019)\d+(\**)\d+$/,
          interpayment: /^(636)\d+(\**)\d+$/,
          unionpay: /^(62|88)\d+(\**)\d+$/,
          visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
          mastercard: /^5[1-5][0-9]{14}$/,
          amex: /^3[47][0-9]{13}$/,
          diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
          discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
          jcb: /^(?:2131|1800|35\d{3})\d{11}$/
        };
        var substr = number.replace(/\*/g, "1");
        for (var key in re) {
          if (re[key].test(substr)) {
            return "/credit-card/" + key + "-curved-64px.png";
          }
        }
        return "/credit-card/visa-curved-64px.png";
      }
      return "/credit-card/generic-credit-card.png";
    }
  }
};
